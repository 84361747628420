/* Base styles for the about section */
.about {
  width: 80%; /* Sets the width of the about section to 80% of the parent element */
  margin-left: 7rem; /* Left margin to create space from the left edge */
  margin-right: 7rem; /* Right margin to create space from the right edge */
  display: flex; /* Applies flexbox layout */
  flex-direction: row; /* Aligns child elements (heading, image, content wrapper) in a row */
  align-items: center; /* Vertically centers the child elements */
  text-align: justify; /* Justifies the text inside the section */
}

/* Heading section styling */
.heading {
  width: 30%; /* The width of the heading takes up 30% of the container */
  font-size: 1.15rem; /* Sets a moderate font size for the heading */
}

/* Image styling */
._img {
  margin: 10em; /* Creates large margin around the image (top, bottom, left, right) */
  width: 35em; /* Sets the width of the image */
  height: 20em; /* Sets the height of the image */
  border-radius: 16px; /* Rounds the corners of the image */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Applies a subtle box shadow for depth */
  border: 1px solid rgba(255, 255, 255, 0.51); /* Applies a thin border around the image */
}

/* Content wrapper for text content */
._content_wrapper {
  width: 40%; /* Takes up 40% of the container's width */
}

/* Contact button styling */
.contact-btn {
  margin-right: 20px; /* Adds space to the right of the button */
  text-decoration: none; /* Removes underline from the link */
  color: #00FF00; /* Sets the text color to green */
}

/* Hover effect for the contact button */
.contact-btn:hover {
  margin-right: 20px; /* Keeps the margin on hover */
  text-decoration: none; /* Removes underline on hover */
  background-color: #ec704c; /* Changes background color to orange on hover */
}

/* Media query for medium screens (up to 1024px wide) */
@media (max-width: 1024px) {
  .about {
    margin-left: 3rem; /* Reduces left margin */
    margin-right: 3rem; /* Reduces right margin */
  }

  ._img {
    margin: 2em; /* Reduces margin around the image */
  }

  ._content_wrapper {
    width: 40%; /* Keeps content wrapper width the same */
  }
}

/* Media query for small screens (up to 768px wide) */
@media (max-width: 768px) {
  .about {
    margin-left: 4em; /* Adjusts left margin for small screens */
    margin-right: 4em; /* Adjusts right margin for small screens */
    flex-direction: column; /* Stacks the elements (heading, image, content) vertically */
    text-align: justify; /* Keeps the text justified */
  }

  ._img {
    width: 15em; /* Reduces the width of the image */
    height: 12em; /* Reduces the height of the image */
  }

  ._content_wrapper {
    width: 15em; /* Reduces the width of the content wrapper */
  }
}
