.project {
  width: 80%;
  margin-left: 7rem;
  margin-right: 7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s ease-in-out; /* Ensure smooth transition when expanding description */
}

.__img_wrapper {
  width: 60em;
  position: relative;
}

.laptop-border {
  background: url('../../assets/laptop-border.png') no-repeat center center;
  background-size: contain;
  position: relative;
  width: 100%;
  padding-top: 58%; /* Aspect ratio */
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-image {
  position: absolute;
  width: 55%;
  top: 20%;
  height: auto;
  object-fit: contain;
}

.__content_wrapper {
  width: 40%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.51);
}

.title {
  text-align: center;
  font-size: 1.5rem;
  margin: 0;
}

.description {
  text-align: justify;
  font-size: 1rem;
  line-height: 1.6;
  width: 60%;
  height: 6em; /* Limit to 6 lines */
  overflow: hidden; /* Hide the extra lines */
  transition: height 0.3s ease-in-out; /* Smooth transition when expanding */
}

.description.expanded {
  height: auto; /* Full height when expanded */
}

@media (max-width: 1024px) {
  .project {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .__content_wrapper {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .project {
    margin-left: 4em;
    margin-right: 4em;
    flex-direction: column;
  }
  .__img_wrapper {
    width: 400px;
  }
  .__content_wrapper {
    width: 70%;
  }
  .title2 {
    position: absolute; /* or relative */
    left: 35%;
    bottom: -5%;
}
  
}
