nav {
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  transform: rotate(-90deg) translate3d(-50%, 0, 0);
  top: 15%;
  left: -10rem;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out; /* Smooth transition */
  pointer-events: none; /* Disable interactions when hidden */
}

nav.show {
  opacity: 1; /* Show the sidebar */
  pointer-events: auto; /* Enable interactions */
}

nav a {
  margin-right: 20px;
  text-decoration: none;
  color: #575757;
}

nav a:hover {
  color: #ec704c;
}

nav a.active {
  color: #ec704c;
}
