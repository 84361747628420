/* Certificates section styling */
.certificates-section {
  padding: 40px; /* Adds padding around the certificates section */
  margin-top: 40px; /* Adds margin at the top to separate from previous content */
  text-align: center; /* Centers content horizontally (for small screens) */

}

/* Wrapper for the certificates list */
.certificates-wrapper {
  width: 80%; /* Restricts the width of the wrapper to 80% */
  margin: 0 auto; /* Centers the wrapper horizontally */
  overflow: hidden; /* Prevents overflow of elements outside the wrapper */
  padding: 20px; /* Adds padding inside the wrapper */
}

/* Thumbnails container */
.certificates-thumbnails {
  display: flex; /* Uses flexbox for horizontal layout */
  gap: 15px; /* Adds gap between thumbnails */
  overflow-x: scroll; /* Allows horizontal scrolling for thumbnails */
  padding: 10px 0; /* Adds vertical padding */
  justify-content: center; /* Centers thumbnails horizontally */
}

/* Individual thumbnail item styling */
.thumbnail-item {
  cursor: pointer; /* Changes cursor to pointer to indicate interactivity */
}

/* Styling for the thumbnail image */
.thumbnail-image {
  width: 100px; /* Sets thumbnail width */
  height: 50px; /* Sets thumbnail height */
  object-fit: cover; /* Ensures the image fills the area without distorting */
  border-radius: 5px; /* Rounds the corners of the image */
  transition: transform 0.3s ease; /* Smooth transformation on hover */
}

/* Hover effect for thumbnail images */
.thumbnail-image:hover {
  transform: scale(1.1); /* Enlarges the thumbnail on hover */
}

/* Preview container for displaying certificate images */
.preview-container {
  display: flex; /* Uses flexbox to align content */
  align-items: center; /* Centers content vertically */
  justify-content: center; /* Centers content horizontally */
  position: relative; /* Enables positioning of navigation buttons */
  width: 100%; /* Takes up full width of the container */
  height: 500px; /* Sets fixed height for the preview container */
  margin-top: 20px; /* Adds top margin for spacing */
}

/* Navigation buttons for preview images */
.preview-nav-btn {
  background-color: rgba(0, 0, 0, 0.5); /* Dark background with transparency */
  color: white; /* White text color */
  border-radius: 50%; /* Circular buttons */
  position: absolute; /* Positioned relative to the preview container */
  top: 50%; /* Aligns vertically in the middle */
  z-index: 10; /* Ensures the buttons appear above other elements */
  transform: translateY(-50%); /* Centers the buttons vertically */
}

/* Left navigation button positioning */
.preview-nav-btn:first-child {
  left: 10px; /* Positions the button to the left */
}

/* Right navigation button positioning */
.preview-nav-btn:last-child {
  right: 10px; /* Positions the button to the right */
}

/* Styling for the preview image */
.preview-image {
  max-width: 90%; /* Ensures the image doesn't exceed 90% of the container width */
  max-height: 90%; /* Ensures the image doesn't exceed 90% of the container height */
  object-fit: contain; /* Ensures the image maintains aspect ratio within the container */
}

/* Responsive Design */

/* Medium screens (up to 1024px) */
@media (max-width: 1024px) {
  .certificates-wrapper {
    width: 90%; /* Increases width to 90% for medium screens */
  }

  .certificates-section {
    padding-top: 20px; /* Reduces top padding on medium screens */
  }

  .preview-container {
    height: 400px; /* Reduces height of the preview container for medium screens */
  }
}

/* Small screens (up to 768px) */
@media (max-width: 768px) {
  /* Center text and adjust padding for small screens */
  .certificates-section {
    text-align: center; /* Centers text for small screens */
    padding-top: 30px; /* Adds top padding for small screens */
    //margin-left: 30px; /* Adds left margin for small screens */
    margin-left: 3rem; /* Left margin to create space from the left edge */
    margin-right: -.5rem; /* Right margin to create space from the right edge */
  }

  .certificates-wrapper {
    width: 100%; /* Full width for certificates wrapper on small screens */
  }

  .certificates-thumbnails {
    flex-direction: row; /* Stacks thumbnails vertically */
    align-items: center; /* Centers thumbnails horizontally */
  }

  /* Smaller thumbnails for small screens */
  .thumbnail-image {
    width: 80px; /* Reduces thumbnail width */
    height: 40px; /* Reduces thumbnail height */
  }

  .preview-container {
    height: 350px; /* Reduces preview container height for small screens */
    margin-top: 20px; /* Keeps top margin for spacing */
  }
}

/* Large screens (min-width: 1024px) */
@media (min-width: 1024px) {
  /* Increases padding and layout for large screens */
  .certificates-section {
    padding-top: 50px; /* Adds more top padding for large screens */
  }

  .certificates-wrapper {
    width: 80%; /* Keeps width at 80% for large screens */
  }

  .preview-container {
    height: 600px; /* Increases preview container height for large screens */
  }

  .certificates-thumbnails {
    justify-content: space-between; /* Increases space between thumbnails */
  }

  /* Larger thumbnails for large screens */
  .thumbnail-image {
    width: 120px; /* Increases thumbnail width */
    height: 60px; /* Increases thumbnail height */
  }
}
