/* Contact section styling */
.contact {
  display: flex; /* Uses flexbox for layout */
  width: 80%; /* Sets the width to 80% */
  margin-left: 20rem; /* Adds left margin */
  margin-right: 10rem; /* Adds right margin */
}

/* Form wrapper styling */
._form_wrapper {
  display: flex; /* Uses flexbox for layout within the form */
  border-radius: 16px; /* Rounds the corners of the form container */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for a 3D effect */
  border: 1px solid rgba(255, 255, 255, 0.51); /* Adds a soft border */
  width: 28em; /* Fixed width for the form */
  height: 28em; /* Fixed height for the form */
  padding: 2em; /* Adds padding inside the form container */
}

/* Submit button styling */
.submit-btn {
  margin-right: 20px; /* Adds margin to the right */
  text-decoration: none; /* Removes text decoration */
  color: #ffff; /* White text color */
  background-color: #0a0a0a; /* Dark background color */
  padding: 10px; /* Adds padding inside the button */
  border-radius: 10px; /* Rounds the corners of the button */
}

/* Hover effect for the submit button */
.submit-btn:hover {
  margin-right: 20px; /* Keeps the right margin the same */
  text-decoration: none; /* Ensures no text decoration on hover */
  background-color: #ec704c; /* Changes the background color on hover */
}

/* Contact message styling */
.contact_msg {
  font-size: 4rem; /* Sets the font size of the message */
  //margin: 4rem; /* Adds margin around the message */
}

/* Media Queries */

/* Medium screens (up to 1024px) */
@media (max-width: 1024px) {
  ._form_wrapper {
    width: 350px; /* Adjusts the form width for medium screens */
    height: auto; /* Sets the height to auto to adjust based on content */
    margin-left: -12em; /* Adds left margin for better alignment */
    margin-right: 4em; /* Adds right margin for better alignment */
    padding: 2em; /* Keeps padding consistent */
    //text-align:left;
  }
  .contact_msg{
    font-size:3rem;
    //text-align:center;
  }
}

/* Small screens (up to 768px) */
@media (max-width: 768px) {
  /* Contact section adjustments for small screens */
  .contact {
    width: 70%; /* Reduces the width of the contact section */
    margin-left: 5rem; /* Adds left margin */
    margin-right: 4rem; /* Adds right margin */
    margin-bottom: 7em; /* Adds bottom margin for spacing */
    flex-direction: column; /* Stacks elements vertically */
  }

  /* Form wrapper adjustments for small screens */
  ._form_wrapper {
    width: 100%; /* Takes full width on small screens */
    margin-left: 0; /* Removes left margin */
    margin-right: 0; /* Removes right margin */
    padding: 2em; /* Keeps padding consistent */
  }

  /* Ensures the contact section remains in column layout for small screens */
  .contact {
    flex-direction: column; /* Stacks the form and other content vertically */
  }
  .contact_msg{
    font-size:2rem;
    text-align:center;
  }
}
